export enum LOCATION_EVENTS {
  "FROM" = "from",
  "FROM_VIA" = "from-via",
  "TO" = "mid-stop",
  "RETURN_FROM" = "restart-mid",
  "TO_VIA" = "to-via",
  "RETURN_TO" = "to",
}

export enum USER_STATUS {
  "ACTIVE" = "ACTIVE",
  "PENDING" = "PENDING",
}

export enum SEARCH_TYPE {
  "TEXT" = "text",
  "DATE" = "date",
  "STATUS" = "status",
  "ORGANIZATION" = "organization",
  "INVOICED_TRIP" = "invoiced_trip",
}
/**
 * Not in used just for reference
 *
 * @description Enum for the trip status from BusBusiness
 */
export const TRIP_STATUS = {
  ALL: "all",
  PLANNING: "planning",
  DRAFT: "draft",
  CANCELED: "canceled",
  READY: "ready",
  RUNNING: "running",
  COMPLETE: "complete",
  ABORTED: "aborted",
  CLOSED: "closed",
  PENDING_APPROVAL: "Pending approval",
  APPROVED: "APPROVED",
};

export const ORDER_STATUS = {
  ALL: "all",
  REQUESTED: "requested",
  REQUEST_REJECTED: "request_rejected",
  QUOTATION: "quotation",
  REJECTED: "rejected",
  ACCEPTED: "accepted",
  ACTIVE: "active",
  CANCELED: "canceled",
  COMPLETED: "completed",
};

export const TRIP_TYPE = {
  REQUEST: "new",
  ACTIVE: "active",
  CLOSED: "finished",
  INVOICED: "invoiced",
};

export enum ROLE {
  ADMIN = "admin",
  DRIVER = "driver",
  MASTER_ADMIN = "master-admin",
}

export enum REDIRECTION_ENUM {
  LINK_EXPIRED = "link-expired",
  PROFILE_CREATED = "profile-created",
  DEFAULT = "default",
  INVITATION_VERIFIED = "invitation-verified",
}

export const enum REQUEST_TRIP_ACTIONS {
  ACCEPT = "accept",
  REJECT = "reject",
  BULK_ACCEPT = "bulk_accept",
  BULK_REJECT = "bulk_reject",
}

export const enum ACTIVE_TRIP_ACTIONS {
  START_TRIP = "start-trip",
  CLOSE_TRIP = "direct-close-trip",
  APPLESS = "appless",
  DRIVER_ASSIGN = "driver-assign",
  VEHICLE_ASSIGN = "vehicle-assign",
}

export const enum CLOSED_TRIP_ACTIONS {
  SEND_CLAIM_TO_HOST = "submit-claim",
  SET_READY_TO_INVOICE = "set-ready-to-invoice",
  REDIRECT_TO_FINALIZE = "redirect-to-finalize",
}

export const enum ACTION_MODE {
  GET_SINGLE,
  GET_LIST,
  ADD,
  EDIT,
  QUICK_ASSIGN,
}

export enum VERIFICATION_TYPE {
  COMPANY_INVITATION = "company-invitation",
  EMPLOYEE_INVITATION = "employee-invitation",
  COMPANY_AFFILIATION = "company-affiliation",
}

export enum TRIP_STATUS_ENUM {
  PLANNING = "planning",
  PENDING = "pending",
  "READY" = "ready",
  RUNNING = "running",
  CLOSED = "closed",
}

export enum CTA_LINK {
  HELP = "https://busnetwork.zendesk.com/hc/en-us",
  CONTACT = "https://busnetwork.zendesk.com/hc/en-us",
  ANDROID_APP = "https://play.google.com/store/apps/details?id=app.ferdia.busnetwork_driver",
  IOS_APP = "https://apps.apple.com/app/ferdia-busnetwork-driver/id6478439271",
}

export enum DASHBOARD_URL_HASH_IDS {
  NEED_RESPONSE = "need-response",
  CLOSED_SECTION = "closed-section",
}

export const MAX_BULK_SELECTION_ALLOWED = 10;

export enum FILTER_PARENT_KEY {
  REQUEST_LIST,
  ACTIVE_TRIP_LIST,
  CLOSED_TRIP_LIST,
  INVOICED_LIST,
}

export enum DEVIATION_CONTEXT {
  FROM_ARRIVED = "from_arrived",
  FROM_DEPARTED = "from_departed",
  TO_ARRIVED = "to_arrived",
}

export enum CLAIM_STATUS {
  DRAFT = "draft",
  PENDING = "pending",
  APPROVED = "approved",
  DECLINED = "declined",
}

export enum INVOICE_STATUS {
  READY = "ready",
  EXPORTED = "exported",
}

export enum TRIP_LIST_VIEW {
  REQUEST = "request",
  ACTIVE = "active",
  CLOSED = "closed",
}

export const enum INVOICE_TRIP_ACTIONS {
  EXPORT_READY_INVOICE = "ready_to_invoice",
  EXPORT_ALL_INVOICE = "all",
}
